<script>
import Vue from 'vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

Vue.component("Header",()=>import("@frontend/part/Header.vue"))
export default {
    extends: BaseVue,
        data() {
            return {
                row: {},
                items: [],
                product: [],
                page404:false,
                catProd:"",
                itemDetail:{},
                row:{},
            }
        },
        async mounted() {
            await this.ready();
            this.refreshMeta()
            this.$set(this.$root, 'page', this);
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
            }, 100)
            $(document).ready(() => {
                AOS.init()
            })
            this.catProd = this.$route.params.slug
            this.apiGetProduct()

        },
        methods: {
            apiGetProduct(){
            Gen.apirest('/home-product-preview', {}, (err, resp)=>{
                if(err) console.log(err)
                this.$root.topProgress.done()
                this.product = resp.data
                this.row = resp.slider
            })
        }
        }
};
</script>

<template>
    <div Content>
        <Header></Header>
        <section id="slider" class="slider-element slider-parallax swiper_wrapper clearfix sliderActivities">
            <!-- slider-parallax-inner -->
            <div class="paralaxActivities">
                <div class="swiper-container swiper-parent">
                    <div v-if="!isMobile" class="swiper-wrapper d-none d-md-block"
                        :style="'background-image: url(\''+uploader(row.mhi_image,'1349x350')+'\'); background-size: cover; background-position: top;'">
                        <div class="container clearfix">
                            <div class="slider-caption go-mid dark">
                                <h3 class="mt-n4 mt-md-0">{{row.mhi_title}}</h3>
                                <p class="mt-2">{{row.mhi_desc}}</p>
                            </div>
                        </div>
                        <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                    </div>
                    <div v-else class="swiper-wrapper d-none d-md-block"
                        :style="'background-image: url(\''+uploader(row.mhi_image,'360x240')+'\'); background-size: cover; background-position: top;'">
                        <div class="container clearfix">
                            <div class="slider-caption go-mid dark">
                                <h3 class="mt-n4 mt-md-0">{{row.mhi_title}}</h3>
                                <p class="mt-2">{{row.mhi_desc}}</p>
                            </div>
                        </div>
                        <div class="video-overlay" style="background-color: rgba(0,0,0,0.4);"></div>
                    </div>
                </div>
                <a href="javascript:;" data-scrollto="#content" data-offset="100" class="dark one-page-arrow"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
            </div>
        </section>
        <section id="page-title" class="page-title-mini">
            <div class="container clearfix">
                <h1 class="d-none d-md-block">&nbsp;</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Product</li>
                </ol>
            </div>
        </section>
        <section id="content" class="overflow-none">
            <div class="content-wrap">
                <section class="section  nobottommargin notopmargin">
                    <div class="container">
                        <div class="form-row justify-content-center">
                            <div class="col-sm-6 col-md-4" v-for="(v,k) in product">
                                <div class="prod_farm_wrap mb-md-2 mb-3">
                                    <router-link :to="{name:'Product',params:{slug:v.mp_slug}}">
                                        <VImg v-if="!isMobile" :src="uploader(v.mp_image, '373x249')" :alt="v.mp_sub_title" :title="v.mp_sub_title"></VImg>
                                        <VImg v-else :src="uploader(v.mp_image, '330x219')" :alt="v.mp_sub_title" :title="v.mp_sub_title"></VImg>
                                    </router-link>
                                    <div class="box_prod_name">
                                        <div class="txt_prod">
                                            <h3>{{v.mp_name}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>