const BoConfig = {
    sidebar: [
        {name:"Dashboard",link:"BoDashboard",icon:"icon-speedometer"},
        {name:"Home",icon:"ti-home",childs:[
            {name:"Slide Show",link:"BoSlideShow"},
            {name:"Image Intro About",link:"BoIntroAbout"},
            {name:"Maps",link:"BoMaps"},
            {name:"Static Home",link:"BoStaticHome"},
        ]},
        {name:"About",icon:"icon-info",childs:[
            {name:"Value Company",link:"BoValue"},
            {name:"Our Journey",link:"BoAchievements"},
            {name:"Employee Position",link:"BoEmployeePosition"},
            {name:"Employee Team",link:"BoEmployeeTeam"},
            {name:"Employee",link:"BoEmployee"},
            {name:"About Achivement",link:"BoAboutAchivement"},
            {name:"Heading Image About",link:"BoHeadingImageAbout"},
            {name:"Static About",link:"BoStaticAbout"},
        ]},
        {name:"Product",icon:"icon-social-dropbox",childs:[
            {name:"Product Category",link:"BoProduct"},
            {name:"Product",link:"BoProductDetail"},
            {name:"Heading Image Product",link:"BoHeadingImageProduct"},
        ]},
        {name:"Customer Care",icon:"icon-people",childs:[
            {name:"Direct Mail",link:"BoDirectMail"},
            {name:"Customer Activities",link:"BoCustomerActivities"},
            {name:"Heading Image CC",link:"BoHeadingImageCC"},
            {name:"Static CC",link:"BoStaticCC"},
        ]},
        {name:"News",icon:"icon-book-open",childs:[
            {name:"Journal",link:"BoJournal"},
            {name:"Article",link:"BoArticle"},
            {name:"Category Gallery",link:"BoCategoryGallery"},
            {name:"Gallery",link:"BoGallery"},
            {name:"Heading Image News",link:"BoHeadingImageNews"},
        ]},
        {name:"Contact",icon:"icon-phone",childs:[
            {name:"Message Contact",link:"BoContactUs"},
            {name:"Sales Manager",link:"BoSalesManager"},
            {name:"Heading Image Contact",link:"BoHeadingImageContact"},
        ]},
        {name:"Career",icon:"icon-bag",childs:[
            {name:"Vacancy",link:"BoCareer"},
            {name:"Static Career",link:"BoStaticCareer"},
            {name:"Slider",link:"BoCareerSlider"},
            {name:"Employee Testimony",link:"BoEmployeeTestimoni"},
            {name:"Apply Form",link:"BoApplyForm"},
            {name:"Heading Image Career",link:"BoHeadingImageCareer"},
        ]},        
        {name:"Static",icon:"icon-list",childs:[
            {name:"Static Content",link:"BoStaticContent"},
            {name:"Static SEO",link:"BoSeo"},
        ]},
        {name:"Link",link:"BoLinks",icon:"icon-link"},
        {name:"User Management",icon:"icon-wrench",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
        // WARP
        // {name:"Category",link:"BoCategoryArticle"},
        // {name:"Area",link:"BoSalesArea"},
        // {name:"Web Setting",link:"BoWebSetting"},
        // {name:"Hero Image",link:"BoHeroImage"},
    ],
}
export default BoConfig