<script>
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';
// import swal from 'sweetalert';

export default {
  extends:GlobalVue,
  mounted(){
    this.refreshMeta()
  },
  methods:{
    refreshMeta(){
      Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
        if(err) return console.error(err)
        $("title").text(resp.mss_meta_title)
        $("meta[property='og:image']").attr('content', resp.mss_img)
        $("meta[property='og:title']").attr('content', resp.mss_meta_title)
        $("meta[name='description']").attr('content', resp.mss_meta_desc)
        $("meta[property='og:description']").attr('content', resp.mss_meta_desc)
        $("meta[name='keywords']").attr('content', resp.mss_meta_keyword)
        $("meta[name='author']").attr('content', resp.mss_author)
        $("meta[property='og:site_name']").attr('content', resp.mss_sitename)
        $("meta[property='url']").attr('content', resp.mss_url)
        $("link[rel='canonical']").attr('content', resp.mss_url)
        this.$root.meta = resp
      })
    },
  },
  watch:{
    $route(){
      this.refreshMeta()
    }
  }
}

</script>
